<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="결재요청 정보" class="cardClassDetailForm" topClass="topcolor-orange">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  label="결재요청"
                  icon="check"
                  color="purple"
                  @btnClicked="saveApprRequest" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <div class="col-12">
                    <c-text
                      :editable="false"
                      label="결재 업무명"
                      name="approvalTypeName"
                      v-model="apprtype.approvalTypeName">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <c-text
                      :editable="editable"
                      :required="true"
                      label="결재 요청명"
                      name="approvalRequestName"
                      v-model="reqdata.approvalRequestName">
                    </c-text>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style="margin-top:-15px !important;">
                  <c-table
                    ref="apprline2"
                    title="회람"
                    tableId="apprline2"
                    topBorderClass="topcolor-orange"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :hideBottom="true"
                    :usePaging="false"
                    :filtering="false"
                    :isExcelDown="false"
                    gridHeight="180px"
                    :columns="gridline2.columns"
                    :data="gridline2.data"
                  >
                  </c-table>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top:-15px !important;">
                  <c-table
                    ref="apprline"
                    title="결재선"
                    tableId="apprline"
                    topBorderClass="topcolor-orange"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :hideBottom="true"
                    :usePaging="false"
                    :filtering="false"
                    :isExcelDown="false"
                    gridHeight="180px"
                    :columns="gridline.columns"
                    :data="gridline.data"
                  >
                    <!-- 버튼 영역 -->
                    <template slot="table-button">
                      <q-btn-group outline >
                        <c-btn label="결재선 변경" icon="change_circle" color="orange" @btnClicked="changeApprLine" />
                      </q-btn-group>
                    </template>
                    <template v-slot:customArea="{ props, col }">
                      <template v-if="col.name === 'approvalKindName'">
                        <span class="text-weight-bold" :class="getLineKindColor(props.row.approvalKindCd)">{{props.row.approvalKindName}}</span>
                      </template>
                      <template v-if="col.name === 'approvalStatusName'">
                        <span :class="getLineKindColor2(props.row.approvalStatusName)">{{props.row.approvalStatusName}}</span>
                      </template>
                    </template>
                  </c-table>
                </div>
            </template>
          </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="결재 상세내용" class="cardClassDetailForm displayBlockCard" topClass="topcolor-orange">
            <template slot="card-detail">
              <component :is="component" :popupParam.sync="popupParam.approvalParamValue" />
            </template>
          </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'appr-request',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sysApprovalRequestId: '',
        approvalTypeCd: '', // 결재 유형 코드
        approvalRequestName: '', // 결재 요청명
        approvalParamValue: '', // 결재 파라미터
        approvalConnId: '',  // 결재업무 연결용 업무일련번호
      }),
    },
  },
  data() {
    return {
      editable: true,
      component: null, // 콤포넌트
      detailTypeUrl: '',
      apprLineUrl: '',
      infoUrl: '',
      listUrl: '',
      saveApprRequestUrl: '',
      apprtype: {
        approvalTypeCd: '',  // 결재유형코드
        approvalTypeName: '',  // 결재업무명
        approvalUrl: '',  // 결재화면 page경로
        approvalParam: '',  // 결재파라메터
        approvalContent: '',  // 결재메일내용
      },
      reqdata: {
        approvalTypeCd: '',  // 결재유형코드
        approvalRequestName: '',  // 결재 요청명
        approvalStatusCd: '',  // 결재상태
        approvalParam: '',  // 결재파라메터
      },
      columnsline: [
        {
          name: 'approvalKindName',
          field: 'approvalKindName',
          label: '결재구분',
          align: 'center',
          style: 'width: 80px',
          type: 'custom',
          sortable: false,
        },
        {
          name: 'deptName',
          field: 'deptName',
          label: '부서',
          align: 'center',
          style: 'width: 120px',
          sortable: false,
        },
        {
          name: 'approvalUserName',
          field: 'approvalUserName',
          label: '성명',
          align: 'center',
          style: 'width: 80px',
          sortable: false,
        },
        {
          name: 'approvalStatusName',
          field: 'approvalStatusName',
          label: '결재상태',
          align: 'center',
          style: 'width: 80px',
          type: 'custom',
          sortable: false,
        },
        {
          name: 'returnReason',
          field: 'returnReason',
          label: '반려사유',
          align: 'left',
          type: 'textarea',
          sortable: false,
          disableTarget: 'approvalStatusCd',
          disableCon: 'ASC0000001',
        },
        {
          name: 'approvalDt',
          field: 'approvalDt',
          label: '결재일시',
          align: 'center',
          style: 'width: 160px',
          sortable: false,
        },
      ],
      gridline: {
        columns: [
          {
            name: 'approvalKindName',
            field: 'approvalKindName',
            label: '결재구분',
            align: 'center',
            style: 'width: 20%',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
        ],
        data: [],
      },
      gridline2: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.infoUrl = selectConfig.sys.appr.info.get.url;
      this.listUrl = selectConfig.sys.appr.line.list.url;
      this.detailTypeUrl = selectConfig.sys.appr.type.get.url;
      this.apprLineUrl = selectConfig.sys.appr.line.last.url;
      this.saveApprRequestUrl = transactionConfig.sys.appr.request.insert.url;
      this.reqdata.approvalRequestName = this.popupParam.approvalRequestName;
      if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
        this.gridline.columns = this.columnsline;
        this.getApprInfo();
        this.getApprLines();
      } else { // 첫 결재요청
        this.getApprType();
        this.getApprLine();
        this.getApprLine2();
      }
    },
    getApprLines() {
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      this.$http.url = this.listUrl;
      this.$http.param = {
        sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          let lineData0 = _result.data.filter( x => {
            return x.approvalKindCd != 'AKC0000009';
          });
          let lineData1 = _result.data.filter( x => {
            return (x.approvalKindCd == 'AKC0000001' || x.approvalKindCd == 'AKC0000002' || x.approvalKindCd == 'AKC0000003' || x.approvalKindCd == 'AKC0000004' || x.approvalKindCd == 'AKC0000010') && x.approvalStatusCd != ''
          });
          let lineData2 = _result.data.filter( x => {
            return x.approvalKindCd == 'AKC0000009'
          });
          this.gridline.data = lineData1;
          let cnt = parseInt(this.gridline.data[this.gridline.data.length-1].approvalLineNo);
          this.$_.forEach(lineData0, _item => {
            cnt++;
            this.gridline.data.push({
              approvalKindCd: _item.approvalKindCd == 'AKC0000001' ? 'AKC0000010' : _item.approvalKindCd, // 상신
              approvalKindName: _item.approvalKindName == '상신' ? '재상신' : _item.approvalKindName,
              approvalUserId: _item.approvalUserId,
              approvalUserName: _item.approvalUserName,
              deptName: _item.deptName,
              approvalLineNo: cnt,
              approvalStatusCd: '',
              approvalStatusName: '',
              approvalDt: '',
              draggable: _item.approvalKindCd == 'AKC0000001' ? false : true,
            });
          })
          this.gridline2.data = lineData2;
        }
      },
      () => {
      });
    },
    getApprInfo() {
      this.$http.url = this.infoUrl;
      this.$http.param = {
        sysApprovalRequestId: this.popupParam.sysApprovalRequestId
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.apprtype.approvalTypeName = _result.data.approvalTypeName;
        this.reqdata.approvalRequestName = _result.data.approvalRequestName;
        this.component = () => import(`@/pages${_result.data.approvalUrl}`);
        this.popupParam.approvalParamValue = JSON.parse(_result.data.approvalParam);
        this.$_.extend(this.popupParam.approvalParamValue, {approvalDocType: 'TODO'});
      },
      () => {
      });
    },
    getApprType() {
      this.$http.url = this.$format(this.detailTypeUrl, this.popupParam.approvalTypeCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.apprtype = _result.data;
        this.component = () => import(`@/pages${this.apprtype.approvalUrl}`);
      },
      () => {
      });
    },
    getApprLine() { // 요청자가 상신자인 최근 결재선호출
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람
      this.$http.url = this.apprLineUrl;
      this.$http.param = {
          approvalTypeCd: this.popupParam.approvalTypeCd,
          approvalUserId: this.$store.getters.user.userId,
          approvalKindCd: 'AKC0000001',
          passFlag: 'N',  // 회람자제외
          passFlagKindCd: 'AKC0000009', // 회람자제외
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.gridline.data = _result.data;
          let cnt = 1;
          this.$_.forEach(this.gridline.data, _item => {
            _item.approvalLineNo = cnt;
            cnt++;
          })
        } else {
          // 최근이력이 없을 경우 상신자만 추가
          this.gridline.data.push({
            approvalKindCd: 'AKC0000001', // 상신
            approvalKindName: '상신',
            approvalUserId: this.$store.getters.user.userId,
            approvalUserName: this.$store.getters.user.userName,
            deptName: this.$store.getters.user.deptName,
            approvalLineNo: 1,
            approvalStatusCd: 'ASC0000000', // 결재요청으로 저장
          });
        }
      },
      () => {
      });
    },
    getApprLine2() { // 요청자가 상신자인 최근 회람자호출
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람
      this.$http.url = this.apprLineUrl;
      this.$http.param = {
          approvalTypeCd: this.popupParam.approvalTypeCd,
          approvalUserId: this.$store.getters.user.userId,
          approvalKindCd: 'AKC0000001',
          passFlag: 'Y',  // 회람자제외
          passFlagKindCd: 'AKC0000009', // 회람자제외
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridline2.data = _result.data;
      },
      () => {
      });
    },
    changeApprLine() {
      this.popupOptions.title = '결재선 변경'; // 결재선 변경
      if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
        let line = this.gridline.data.filter( x => {
          return x.approvalDt == ''
        });
        this.popupOptions.param = {
          apprlinedata: line,
          apprlinedata2: this.gridline2.data
        };
      }
      else {
        this.popupOptions.param = {
          apprlinedata: this.gridline.data,
          apprlinedata2: this.gridline2.data
        };
      }
      this.popupOptions.target = () => import(`${'@/pages/common/appr/apprLinePop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeApprLinePopup;
    },
    closeApprLinePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.apprlinedata || data.apprlinedata2) {
        if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
          let oridata = this.gridline.data.filter( x => {
            return x.approvalDt != ''
          });
          this.gridline.data = oridata.concat(data.apprlinedata);
        } else {
          this.gridline.data = data.apprlinedata;
        }
        this.gridline2.data = data.apprlinedata2;
      }
    },
    getLineKindColor(_cd) {
      let color = '';
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람
      switch(_cd) {
        case 'AKC0000001':
        case 'AKC0000010':
          color = 'blue-grey-4';
          break;
        case 'AKC0000002':
          color = 'green-7';
          break;
        case 'AKC0000003':
          color = 'blue-7';
          break;
        case 'AKC0000004':
          color = 'blue-grey-4';
          break;
      }
      return 'text-' + color;
    },
    getLineKindColor2(_cd) {
      let color = '';
      switch(_cd) {
        case '반려':
          color = 'text-weight-bold text-red';
          break;
      }
      return color;
    },
    saveApprRequest() {
      let chgdata = [];
      if(this.reqdata.approvalRequestName == '') {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '결재 요청명을 입력하세요.', // 결재 요청명을 입력하세요.
          type: 'warning', // success / info / warning / error
        });
      } else if (this.gridline.data.length < 2) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '결재선을 등록하세요.', // 결재선을 등록하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
          let ordercnt = 1;
          this.$_.forEach(this.gridline.data, _item => {
            _item.approvalLineNo = ordercnt;

            ordercnt++;
          });
          chgdata = this.gridline.data.filter( x => {
            return x.approvalStatusName == ''
          });
          if (chgdata.length < 2) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '재상신 결재선을 등록하세요.', // 재상신 결재선을 등록하세요.
              type: 'warning', // success / info / warning / error
            });
            return false;
          } else {
            chgdata[0].approvalStatusCd = 'ASC0000000';
            chgdata[1].approvalStatusCd = 'ASC0000001';
          }
        }
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '결재를 요청하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let saveData;
            if (this.popupParam.sysApprovalRequestId) { // 재상신의 경우
              this.$_.extend(this.reqdata, {
                approvalConnId: this.popupParam.approvalConnId, // 결재업무 연결용 업무일련번호
                approvalTypeCd: this.apprtype.approvalTypeCd,  // 결재유형코드
                approvalStatusCd: 'ASC0000001',  // 결재상태 (결재중)
                approvalParam: JSON.stringify(this.popupParam.approvalParamValue),  // 결재상세화면 호출파라메터
              });
              saveData = chgdata.concat(this.gridline2.data);
              this.$http.url = this.saveApprRequestUrl + '/re';
              this.$http.type = 'PUT';
              this.$http.param = {
                approvalRequestModel: this.reqdata,
                approvalLineModel: saveData,
                sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
              };
            } else {
              this.$_.extend(this.reqdata, {
                approvalConnId: this.popupParam.approvalConnId, // 결재업무 연결용 업무일련번호
                approvalTypeCd: this.apprtype.approvalTypeCd,  // 결재유형코드
                approvalStatusCd: 'ASC0000001',  // 결재상태 (결재중)
                approvalParam: JSON.stringify(this.popupParam.approvalParamValue),  // 결재상세화면 호출파라메터
              });
              let ordercnt = 0;
              // 상신자 제외 첫번째 결재자에 결재중상태로 변경
              this.$_.forEach(this.gridline.data, _item => {
                if (ordercnt == 1) {
                  _item.approvalStatusCd = 'ASC0000001';
                }
                ordercnt++;
              })
              saveData = this.gridline.data.concat(this.gridline2.data);
              this.$http.url = this.saveApprRequestUrl;
              this.$http.type = 'POST';
              this.$http.param = {
                approvalRequestModel: this.reqdata,
                approvalLineModel: saveData,
              };
            }
            this.$http.request(() => {
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '결재가 정상적으로 요청되었습니다.', /* 결재가 정상적으로 요청되었습니다. */
                type: 'success', // success / info / warning / error
              });
              this.$emit('closePopup');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
